import React from "react";
import GoogleMapReact from "google-map-react";
import { useStaticQuery, graphql } from "gatsby";

import { assets } from "@util/constants";
import { Query } from "@graphql-types";

const Map = () => {
  const { sanityContactPage }: Query = useStaticQuery(graphql`
    {
      sanityContactPage {
        location {
          lng
          lat
        }
      }
    }
  `);

  if (sanityContactPage == null || sanityContactPage.location == null) {
    return null;
  }

  const mapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    draggable: false,
    draggableCursor: "initial",
  };

  const center = {
    lat: sanityContactPage?.location?.lat,
    lng: sanityContactPage?.location?.lng,
  };
  const MapPin = () => (
    <img alt="map-pin" src={assets.mapPin} height="65px" width="45px" />
  );

  // Important! Always set the container height explicitly
  return (
    //@ts-ignore
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_KEY }}
      defaultCenter={center}
      defaultZoom={17}
      options={mapOptions}
    >
      <MapPin
        //@ts-ignore
        lat={center.lat}
        lng={center.lng}
      />
    </GoogleMapReact>
  );
};

export default Map;
