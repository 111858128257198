import * as React from "react";
import { graphql, PageProps } from "gatsby";

import SEO from "@components/shared/seo";
import { Query } from "@graphql-types";
import ContactInfo from "@components/contact/contactInfo";

interface Props extends PageProps {
  data: Query;
}

export default function Contact({ data }: Props) {
  const { sanityContactPage } = data;

  return (
    <div>
      <SEO seoData={sanityContactPage?.seo} />
      <ContactInfo />
    </div>
  );
}

export const query = graphql`
  query ContactQuery {
    sanityContactPage {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
