import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import { Container, H2, VerticalSeparator } from "@util/standard";
import Map from "./map";
import { BlocksContent } from "@components/shared/sub";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  width: 100%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const MapWrapper = styled(Container)`
  height: 90vh;
  width: 80%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    height: 50vh;
  }
`;

const Info = styled(Container)`
  flex-direction: column;
  width: 70%;
  margin: 90px auto;
`;

const Text = styled.p<{ bold?: boolean; noMargin?: boolean }>`
  font-size: 18px;
  line-height: 25px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin: ${(props) => (props.noMargin ? 0 : "0 0 20px 0")};
`;

const StyledA = styled.a<{ bold?: boolean; noMargin?: boolean }>`
  font-size: 18px;
  line-height: 25px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin: ${(props) => (props.noMargin ? 0 : "0 0 20px 0")};
  color: ${colors.darkBlue};
  text-decoration: none;
`;

const ContactContainer = styled(Container)`
  margin-top: 30px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const AddressContainer = styled(Container)`
  flex-direction: column;
  margin-left: 110px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
`;

const ContactInfo = () => {
  const { sanityContactPage }: Query = useStaticQuery(graphql`
    {
      sanityContactPage {
        location {
          lng
          lat
        }
        adminEmail
        phone
        email
        address {
          _rawColumnContent(resolveReferences: { maxDepth: 4 })
        }
        content {
          _rawColumnContent(resolveReferences: { maxDepth: 4 })
        }
      }
    }
  `);

  if (sanityContactPage == null) {
    return null;
  }

  const { phone, email, address, adminEmail } = sanityContactPage;

  const Contact = () => {
    return (
      <ContactContainer>
        <Container flexDirection="column" margin="15px 0 0 0">
          <Text noMargin>Number</Text>
          <StyledA href={`tel:${phone?.replace(/[()]/g, "")}`}>{phone}</StyledA>
          <Text noMargin>Chair's Email</Text>
          <StyledA href={`mailto:${email}`}>{email}</StyledA>
          <Text noMargin>Administrator's Email</Text>
          <StyledA href={`mailto:${adminEmail}`}>{adminEmail}</StyledA>
        </Container>

        <AddressContainer>
          <BlocksContent blocks={address?._rawColumnContent} fontSize={18} />
        </AddressContainer>
      </ContactContainer>
    );
  };

  return (
    <Wrapper>
      <MapWrapper>
        <Map />
      </MapWrapper>
      <Container width="100%">
        <Info>
          <Container margin=" 0 0 30px 0">
            <VerticalSeparator />
            <H2 margin="0">Contact Us</H2>
          </Container>
          <BlocksContent
            blocks={sanityContactPage.content?._rawColumnContent}
          />

          <Contact />
        </Info>
      </Container>
    </Wrapper>
  );
};

export default ContactInfo;
